<template>
  <div class="pubeixi_voteCourItem_container global_vote_font">
    <div
      class="voteCourItem_doctorInfo"
      :class="{ voteCourItem_doctorInfo_active: index % 2 !== 0 }"
    >
      <div class="voteCourItem_doctorInfo_num">
        <!-- {{ index + 1 }} -->
      </div>
      <div class="pubeixi_avater_content">
        <img :src="item.cover" class="pubeixi_voteCourItem_avater" />
        <!-- <img
          v-if="item.rank == 1 && item.vote_count !== 0 && !isVote"
          :src="require('@/assets/images/challenge/trophy.png')"
          class="voteCourItem_trophy"
        /> -->
      </div>
      <div class="voteCourItem_Info">
        <p class="pubeixi_voteCourItem_doctor_hospital">
          {{ item.doctor_hospital }}
        </p>
        <p class="voteCourItem_doctor_name">{{ item.doctor_name }}医生</p>
        <div class="voteCourItem_Info_courname">
          {{ item.courseware_name }}
        </div>
      </div>
      <!-- <div class="votingItem_attention">
        <div class="votingItem_attention_block">
          <img
            ref="imgRef"
            :src="getImage()"
            class="votingItem_attention_img"
          />
          <div>{{ item.vote_count }}</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hidden: "hidden",
    };
  },
  props: {
    item: Object,
    index: Number,
    isVote: Boolean,
    activeType: Boolean,
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.item.vote_count = this.item.vote_count ? this.item.vote_count : 0;
    },
    getImage() {
      let name = this.item.voteForMeCount ? "attention-1" : "attention";
      return require(`@/assets/images/challenge/${name}.png`);
    },
  },
};
</script>

<style scoped>
.pubeixi_voteCourItem_container {
  width: 100%;
  padding-right: 5.33vw;
  padding-bottom: 2vw;
  background: #f2f2f3;
  font-size: 3.2vw;
  border-radius: 2vw;
  position: relative;
}
.pubeixi_voteCourItem_container .voteCourItem_doctorInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pubeixi_voteCourItem_container .voteCourItem_doctorInfo_num {
  margin: 0 2.67vw 0 5.33vw;
}
.pubeixi_voteCourItem_container .voteCourItem_doctorInfo_num_hidden {
  visibility: hidden;
}
.pubeixi_voteCourItem_container .pubeixi_avater_content {
  position: relative;
  margin: 0 2.67vw;
  /* margin-right: 2.67vw; */
  height: 20vw;
}
.pubeixi_voteCourItem_container .pubeixi_voteCourItem_avater {
  width: 100%;
  height: 100%;
  background: #fff !important;
}

.pubeixi_voteCourItem_container .voteCourItem_trophy {
  width: 4.67vw;
  height: 4.67vw;
  position: absolute;
  top: 0;
  left: 0;
}
.pubeixi_voteCourItem_container .voteCourItem_Info {
  height: 20vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 2.6vw;
}
/* .pubeixi_voteCourItem_container .voteCourItem_Info p {
  font-size: 3.73vw;
  font-weight: bold;
} */
.pubeixi_voteCourItem_container .pubeixi_voteCourItem_doctor_hospital {
  font-size: 3.73vw;
  font-weight: bold;
  color: #004890;
}
.pubeixi_voteCourItem_container .voteCourItem_doctor_name {
  margin-top: 1vw;
  font-size: 3.73vw;
}
.pubeixi_voteCourItem_container .voteCourItem_doctorInfo_active {
  /* background-image: linear-gradient(to right, #b7c9db, rgb(242, 242, 243)); */
}
.pubeixi_voteCourItem_container .votingItem_attention {
  width: 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #8d8e8d;
  margin-left: 2.67vw;
}
.pubeixi_voteCourItem_container .votingItem_attention_block {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pubeixi_voteCourItem_container .votingItem_attention_img {
  width: 4vw;
  height: 4vw;
}
.pubeixi_voteCourItem_container .votingItem_attention div {
  text-align: end;
}
.pubeixi_voteCourItem_container .voteCourItem_Info_courname {
  height: 8vw;
  line-height: 3.9vw;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
</style>