<template>
  <div class="pubeixiVote_container global_vote_font">
    <div class="vote_search">
      <el-input
        ref="searchRef"
        v-model="localSearch.content"
        @blur="onblur"
        @focus="onfocus"
        @input="onInput"
      ></el-input>
      <span
        @click="setFocus"
        v-if="!localSearch.content && showIcon"
        class="vote_search_placeholder"
        ><i class="el-icon-search"></i>
        <span> 请输入关键词搜索</span>
      </span>
    </div>
    <pubeixiVoteType
      ref="voteTypeRef"
      :period="search.period"
      :periodList="config.periodList"
      :activeType="localSearch.activeType"
      @selectChange="selectChange"
      @handleVoteMethodGroup="handleVoteMethodGroup"
      @handleVoteMethodName="handleVoteMethodName"
      :voteStartTime="voteStartTime"
      :voteEndTime="voteEndTime"
      :timeStyle="getTimeStyle()"
      :getSelectClass="getSelectClass()"
      :getButtonClass="getButtonClass()"
    ></pubeixiVoteType>
    <div class="vote_content">
      <div class="vote_content_block">
        <ul
          class="ul_englishLetter"
          v-show="localSearch.activeType"
          ref="ulRef"
        >
          <li
            :class="getLetterStyle(item)"
            @click="handleLetter(item)"
            ref="liRef"
            v-for="item in config.englishLetter"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
        <div class="scroll_block">
          <div ref="wrapper" class="vote_content_scroll">
            <div class="scroll_div" ref="voteItem">
              <div class="vote_groups" v-show="!localSearch.activeType">
                <div
                  class="group_block"
                  :class="getGroupStyle()"
                  v-for="item in getGroupsList()"
                  :key="item.group"
                >
                  <div @click="handleVote(item.group)">
                    {{ item.groupName }}
                  </div>
                </div>
              </div>
              <!-- <div class="icon_content" ref="iconContentRef" v-if="loadMode()">
                <div class="icon_content_item">
                  <img
                    class="icon_big"
                    :src="
                      require('@/assets/images/literatureLimit/total-votes-1.png')
                    "
                  /><span>专家票数+大众票数总和</span>
                </div>
                <div class="icon_content_item">
                  <img
                    class="icon_small"
                    :src="
                      require('@/assets/images/literatureLimit/public-votes-1.png')
                    "
                  /><span>大众票数</span>
                </div>
                <div class="icon_content_item">
                  <img
                    class="icon_small"
                    :src="
                      require('@/assets/images/literatureLimit/proficient-votes-1.png')
                    "
                  /><span>专家票数(专家票数1票为10票)</span>
                </div>
              </div> -->
              <div
                class="vote_period"
                v-show="localSearch.activeType"
                ref="voteList"
              >
                <div
                  @click="goToDetail(item)"
                  v-for="(item, index) in getDataList()"
                  :key="item.vote_id"
                >
                  <voteCourItem
                    :index="index"
                    :item="item"
                    :isVote="isVote"
                  ></voteCourItem>
                  <!-- <newVoteCourItem
                    v-else
                    :index="index"
                    :item="item"
                    :isVote="isVote"
                    :role="role"
                    :mode="loadMode()"
                  ></newVoteCourItem> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pubeixi_voteFooter">
      <p>
        本项目中会传递医药前沿信息、研究进展和科学信息交流,仅供医疗卫生专业人士访问、参考及学习,请确保您的身份是医疗卫生专业人士,否则请退出。视频未经讲者允许,禁止任何形式转载
      </p>
    </div>
  </div>
</template>
<script>
import voteCourItem from "./voteCourItem.vue";
import pubeixiVoteType from "./pubeixiVoteType.vue";

import tool from "@/common/tool";
import urls from "@/common/urls";
import config from "@/common/config";
import wx from "@/common/weixin";
import BScroll from "better-scroll";
import { ElInput } from "element-plus";
export default {
  name: "pubeixiVote",
  components: {
    voteCourItem,
    pubeixiVoteType,
    ElInput,
  },
  data() {
    return {
      config: {
        allVoteList: [],
        periodList: [],
        myVoteList: [],
        englishLetter: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
        groups: [],
      },
      localSearch: {
        activeType: 0,
        content: "",
        group: "",
      },
      search: {
        project_id: config.projectId,
        company_id: config.companyId,
        period: "",
        page: 1,
        page_size: 1000,
      },
      activeLetter: "",
      showIcon: true,
      params: {},
      itemHeights: [],
      scroll: null,
      voteStartTime: [],
      voteEndTime: [],
      isVote: "",
      role: null,
      doctorInfo: {},
    };
  },
  created() {
    this.doctorInfo = this.$store.state.doctorInfo || {}; //快课堂医生信息
    this.getStorageData();
    this.role = Number(this.$route.query.role);
    this.loadMode();
  },
  mounted() {
    this.initScroll();
    this.config.periodList = this.formatPeriodList([1, 2, 3, 4, 5, 6, 7]);
    this.loadPeriodList();

    setTimeout(() => {
      this.getTitle();
    }, 1000);
  },
  methods: {
    //判断是否是本组专家
    getCurrDoctor() {
      if (this.doctorInfo.name) {
        let project_info = this.doctorInfo.project_info.find((item) => {
          if (item.project_id == this.search.project_id) return item;
        });
        if (project_info) {
          for (
            let index = 0;
            index < project_info.period_info.length;
            index++
          ) {
            if (project_info.period_info[index].period == this.search.period) {
              return project_info.period_info[index].class_group[0];
            }
          }
        }
      }
    },
    getTitle() {
      document.getElementsByTagName("title")[0].innerHTML = "文献精读视频专区";
    },
    //我要投票按钮
    getBtnColor() {
      if (!this.loadMode()) {
        if (!this.isVote) {
          return "vote_group_vote_btn_ac";
        }
        return "pubeixi_mass_group_vote_btn";
      } else {
        if (!this.role) {
          if (!this.isVote) {
            return "vote_group_vote_btn_ac ";
          }
          return "pubeixi_mass_group_vote_btn";
        } else {
          if (!this.isVote) {
            return "vote_group_vote_btn_ac ";
          }
          return "pubeixi_profic_group_vote_btn";
        }
      }
    },
    //下拉框
    getSelectClass() {
      if (!this.loadMode()) {
        return "pubeixi_mass_type";
      } else if (!this.role) return "pubeixi_mass_type";
      else return "pubeixi_profic_type";
    },
    //类型按钮
    getButtonClass() {
      if (!this.loadMode()) {
        return "pubeixi_mass_type_item_active";
      } else if (!this.role) return "pubeixi_mass_type_item_active";
      else return "pubeixi_profic_type_item_active";
    },
    //投票时间样式
    getTimeStyle() {
      if (!this.loadMode()) {
        return "background";
      } else if (!this.role) return "background";
      else return "background_new";
    },
    //组数据样式
    getGroupStyle() {
      if (!this.loadMode()) {
        return "pubeixi_group_block_shadow";
      } else if (!this.role) return "gpubeixi_roup_block_shadow";
      else return "pubeixi_group_block_shadow_new";
    },
    //首字母样式activeLetter
    getLetterStyle(item) {
      if (item == this.activeLetter) {
        if (!this.loadMode()) {
          return " pubeixi_activeMassLetter";
        } else if (!this.role) return "pubeixi_activeMassLetter";
        else return "pubeixi_activeLetter";
      }
    },
    //判断模式 true 新模式 false 旧模式
    loadMode() {
      return String(this.search.period).includes("-");
    },
    getStorageData() {
      let searchData = tool.getStorage("searchData", sessionStorage);
      if (searchData) {
        this.search = searchData.search;
        this.localSearch = searchData.localSearch;
      }
    },
    setStorageData() {
      console.log(this.search);
      tool.setStorage(
        "searchData",
        {
          search: this.search,
          localSearch: this.localSearch,
        },
        sessionStorage
      );
    },
    loadVoteList() {
      let url = tool.getURL(urls.vote.voteList, this.search);
      this.$axios
        .get(url)
        .then((res) => {
          let item =
            res.data.length > 0
              ? { ...res.data[0] }
              : {
                  start_time: tool.getDate(null, "yyyy-MM-dd"),
                  end_time: tool.getDate(null, "yyyy-MM-dd"),
                };
          this.voteStartTime = item.start_time.split(/[\s:-]/);
          this.voteEndTime = item.end_time.split(/[\s:-]/);
          this.isVote = this.isCanVote(item);
          this.loadMyVote(res.data);
        })
        .catch((err) => {});
    },
    isCanVote(item) {
      return (
        tool.getTime(item.start_time) < Date.now() &&
        tool.getTime(item.end_time) > Date.now()
      );
    },
    loadMyVote(data) {
      let url = tool.getURL(urls.vote.myVote, this.search);
      this.$axios
        .get(url)
        .then((res) => {
          this.config.myVoteList = res.data.filter(
            (item) => tool.getTime(item.subdate) >= tool.getTime(tool.getDate())
          );
          this.initData(data);
        })
        .catch((err) => {});
    },
    formatPeriodList(arr) {
      let numbs = [];
      arr.forEach((item) => {
        if (!item.length || item.length <= 1) {
          item = `2022-${item}`;
        }
        numbs.push(item);
      });
      return numbs.map((num) => {
        let year = num.split("-")[0];
        let month = parseInt(num.split("-")[1]);
        return {
          value: year === "2022" ? month : `${year}-${month}`,
          label: `${year}第${tool.intToChinese(month)}期`,
        };
      });
    },
    loadPeriodList() {
      let url = tool.getURL(urls.vote.periodList, {
        ...this.search,
        check_type: 0,
      });

      this.$axios
        .get(url)
        .then((res) => {
          this.config.periodList = this.formatPeriodList(res.data);
          let data = tool.getStorage("searchData", sessionStorage);
          this.search.period =
            (data && data.search.period) ||
            Number(this.$route.query.period) ||
            this.$route.query.period ||
            [...this.config.periodList].shift().value;

          this.loadVoteList();
        })
        .catch((err) => {});
    },
    initData(data) {
      let voteConfig = {};
      this.config.myVoteList.forEach((item) => {
        voteConfig[item.vote_id] = voteConfig[item.vote_id] || 0;
        voteConfig[item.vote_id] = voteConfig[item.vote_id] += 1;
      });
      let totalCount = data.reduce((total, item) => total + item.vote_count, 0);
      let tempGroup = [];
      let rank = 0;
      let voteCount = 0;
      this.config.allVoteList = data
        .sort((prep, next) => (prep.vote_count < next.vote_count ? 1 : -1))
        .map((item, index) => {
          if (!tempGroup.includes(item.courseware_name))
            tempGroup.push(item.courseware_name);
          let currentVoteCount = item.vote_count;
          if (voteCount !== currentVoteCount) {
            rank = index + 1;
            voteCount = currentVoteCount;
          }
          rank = rank || 1;
          item.rank = rank;
          item.voteForMeCount = voteConfig[item.id] || 0;
          item.totalCount = totalCount;
          item.class_group =
            item.class_group || tempGroup.indexOf(item.courseware_name) + 1;
          return item;
        });
      this.config.allVoteList = this.config.allVoteList.sort((prep, next) =>
        prep.doctor_china_name > next.doctor_china_name ? 1 : -1
      );
      let groups = [];
      this.config.allVoteList.forEach((item) => {
        let group = item.class_group || 1;
        let current = groups[group - 1] || {
          group,
          list: [],
          classGroupImage: item.class_group_image,
          groupName: `第${tool.intToChinese(group)}组：${item.courseware_name}`,
          coursewareName: item.courseware_name,
          groupNameCh: tool.intToChinese(group),
        };
        current.list.push(item);
        groups[group - 1] = current;
      });
      this.config.groups = groups;

      this.$nextTick(() => {
        this.initScroll();
        // this.initParams();
      });
    },
    initParams() {
      let title = "";
      let logo = "";
      let search = {
        activeType: this.localSearch.activeType ? 1 : 0,
      };
      let vote = this.config.allVoteList[1];
      this.params = {
        title: `${vote.sign_project}最佳人气奖评选`,
        desc: `我们是${
          vote ? vote.sign_project : ""
        }医生。\n快来为我们点赞加油吧!`,
        // url: tool.getURL(`${config.host}/#/massVote`, search),
        url: "",
        logo: "http://ior.imedunion.com/files/share.png",
      };
      this.shareMessage();
    },
    shareMessage() {
      wx.sharePageMessage(this.params);
    },
    getGroupsList() {
      return this.config.groups.filter((item) =>
        item.groupName.includes(this.localSearch.content)
      );
    },
    getDataList() {
      return this.config.allVoteList.filter((item) =>
        item.doctor_name.includes(this.localSearch.content)
      );
    },
    getItemHeight() {
      this.$nextTick(() => {
        if (!this.$refs.voteList) return;
        let childrens = this.$refs.voteList.children;
        this.itemHeights = [...childrens].map((item) => item.clientHeight);
      });
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.wrapper, {
            click: true,
            probeType: 3,
            mouseWheel: true,
            scrollbar: {
              fade: false,
              interactive: false, // 1.8.0 新增
            },
          });
          this.scroll.on("scroll", (pos) => {
            this.dealWithScroll(pos);
          });
        } else {
          this.scroll.refresh();
          this.scroll.scrollTo(0, 0);
        }
      });
    },
    dealWithScroll(pos) {
      if (this.itemHeights.length == 0) return;
      let count = 0;
      let currentIndex = 0;
      let index = 0;
      while (index < this.itemHeights.length) {
        count += this.itemHeights[index];
        if (count > Math.abs(pos.y)) {
          currentIndex = index;
          break;
        }
        index++;
      }
      let list = this.getDataList();
      if (list.length == 0) return;
      this.activeLetter = list[currentIndex].doctor_letter;
    },
    handleLetter(item) {
      this.activeLetter = item;
      let indexLetter = this.getDataList().findIndex(
        (val) => val.doctor_letter == item
      );
      if (!this.$refs.voteList) return;
      let childrens = this.$refs.voteList.children;
      let iconContentH = 0;
      if (this.$refs.iconContentRef) {
        iconContentH = this.$refs.iconContentRef.clientHeight;
      }
      let itemHeight = [...childrens].map((item) => item.clientHeight)[0];
      let top = indexLetter == -1 ? top : -itemHeight * indexLetter;
      this.scroll.scrollTo(0, top - iconContentH);
    },
    goToDetail(item) {
      this.setStorageData();
      this.$router.push({
        path: "/pubeixiVoteDetail",
        query: {
          vote_id: item.id,
          role: this.role,
          mode: this.loadMode() ? "1" : "0",
        },
      });
    },
    selectChange(period) {
      if (period) {
        this.search.period = period;
      }
      this.loadVoteList();
      this.setStorageData();
      this.loadMode();
      this.$nextTick(() => {
        this.initScroll();
      });
      this.getTitle();
    },
    onInput() {
      this.setStorageData();
      this.$nextTick(() => {
        this.initScroll();
      });
      if (this.localSearch.content == "") {
        this.$refs.liRef.forEach((v) => (v.className = ""));
      }
    },
    setFocus() {
      this.showIcon = false;
      this.$refs.searchRef.focus();
    },
    onblur() {
      this.$refs.searchRef.blur();
      this.showIcon = true;
    },
    onfocus() {
      this.showIcon = false;
    },
    handleVoteMethodGroup() {
      this.localSearch.activeType = 0;
      this.localSearch.content = "";
      this.setStorageData();
      this.$nextTick(() => {
        this.initScroll();
        this.initParams();
      });
    },
    handleVote(group) {
      this.setStorageData();
      this.$router.push({
        path: "/pubeixiVoteGroupList",
        query: {
          project_id: this.search.project_id,
          class_group: group,
          period: this.search.period,
          role: this.role,
        },
      });
      this.$nextTick(() => {
        this.initScroll();
        this.initParams();
      });
    },
    handleVoteMethodName() {
      if (this.localSearch.activeType) return;
      this.localSearch.activeType = 1;
      this.localSearch.content = "";
      this.setStorageData();
      this.$nextTick(() => {
        this.initScroll();
        this.getItemHeight();
        this.initParams();
      });
    },
  },
};
</script>
<style >
.pubeixiVote_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: #f2f2f3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.pubeixiVote_container .vote_search {
  padding: 0 5.33vw;
  width: 100%;
  height: 6.67vw;
  position: relative;
  color: #dcdfe6;
  margin-top: 2.67vw;
  display: flex;
  align-items: center;
  flex: none;
}
.pubeixiVote_container .vote_search input {
  font-size: 3.2vw !important;
  padding: 0 7vw;
  /* padding-right: 7vw; */
  height: 6.67vw;
  line-height: 6.67vw;
  border: none;
  text-align: center !important;
  border-radius: 4vw;
}
.pubeixiVote_container .vote_search_placeholder {
  font-size: 3.2vw;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #c6c8c7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pubeixiVote_container .vote_search_placeholder i {
  margin-right: 1.33vw;
  color: #5f5f5f;
}
.pubeixiVote_container .vote_content {
  flex: 1 0 0;
  width: 100%;
  font-size: 5vw;
  overflow: hidden;
}
.pubeixiVote_container .vote_content_block {
  height: 100%;
  display: flex;
}
.pubeixiVote_container .vote_content_right {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.pubeixiVote_container .vote_content .scroll_block {
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1 0 0;
  padding-bottom: 3.67vw;
}
.pubeixiVote_container .vote_content_scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.pubeixiVote_container .vote_groups {
  padding-top: 0.67vw;
}
.scroll_block .bscroll-vertical-scrollbar {
  top: 30.5vw !important;
}
.scroll_block .bscroll-indicator {
  height: 150px !important;
}
.pubeixiVote_container .prompt_info {
  vertical-align: middle;
  padding: 2.67vw 5.33vw;

  font-size: 2vw;
  flex: none;
  display: flex;
  align-items: center;
}
.pubeixiVote_container .prompt_info p {
  line-height: 3.5vw;
}
.pubeixiVote_container .ul_englishLetter {
  list-style: none;
  height: 74%;
  overflow-y: scroll;
  width: 3vw;
  flex: none;
  padding: 0 4vw 0 5.33vw;
  margin-top: 3.67vw;
  position: absolute;
  z-index: 10;
}
.pubeixiVote_container .ul_englishLetter li {
  text-align: center;
  font-size: 2.13vw;
  line-height: 5vw;
}
.pubeixiVote_container .pubeixi_activeLetter {
  color: #004890;
  font-size: 3.6vw !important;
}
.pubeixiVote_container .pubeixi_activeMassLetter {
  color: #004890;
  font-size: 3.6vw !important;
}
.pubeixiVote_container .group_block {
  word-break: break-all;
  line-height: 5vw;
  font-size: 3.5vw;
  background: #fff;
  padding: 4vw;
  display: flex;
  flex-direction: column;
  margin: 3.67vw 5.33vw 5vw 5.33vw;
  border-radius: 2vw;
  font-weight: bold;
}
.pubeixiVote_container .pubeixi_group_block_shadow {
  box-shadow: 0 0 1.52vw 0.5vw #82a4c5;
}
.pubeixiVote_container .pubeixi_group_block_shadow_new {
  box-shadow: 0 0 1.52vw 0.5vw #82a4c5;
}
.pubeixiVote_container .vote_group_vote_btn {
  font-size: 3.2vw;
  height: 5.33vw;
  line-height: 1vw;
  /* width: 20vw; */
  border-radius: 5.33vw;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 2vw;
  padding: 0 4vw;
}
.pubeixiVote_container .vote_group_vote_tip {
  font-size: 3.2vw;
  height: 5.33vw;
  line-height: 1vw;
  /* width: 20vw; */
  border-radius: 5.33vw;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  margin-top: 2vw;
  padding: 0 4vw;
}
.pubeixiVote_container .pubeixi_mass_group_vote_btn {
  background: #004890;
}
.pubeixiVote_container .pubeixi_profic_group_vote_btn {
  background: #004890;
}
.pubeixiVote_container .vote_group_vote_btn_ac {
  background: #909499;
}
.pubeixiVote_container .vote_period > div {
  margin-top: 3.67vw;
}
.pubeixiVote_container .background {
  background: #eadce4;
}
.pubeixiVote_container .background_new {
  background: #f3f8e3;
}
.pubeixiVote_container .icon_content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.pubeixiVote_container .icon_content_item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2vw;
}
.pubeixiVote_container .icon_content .icon_small {
  width: 4vw;
  height: 4vw;
}
.pubeixiVote_container .icon_content .icon_big {
  width: 5vw;
  height: 5vw;
}
.pubeixiVote_container .pubeixi_voteFooter {
  vertical-align: middle;
  padding: 2.67vw 5.33vw;
  font-size: 2vw;
  flex: none;
  display: flex;
  align-items: center;
  background: linear-gradient(#e6e9ee, #d1dae5);
}
.pubeixi_voteFooter p {
  line-height: 3.5vw;
}
</style>  